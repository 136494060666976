exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-features-iframe-tsx": () => import("./../../../src/pages/features-iframe.tsx" /* webpackChunkName: "component---src-pages-features-iframe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-4-effective-popup-advertising-strategies-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/4-effective-popup-advertising-strategies.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-4-effective-popup-advertising-strategies-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-a-guide-to-lead-generation-what-is-it-how-to-improve-it-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/a-guide-to-lead-generation-what-is-it-how-to-improve-it.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-a-guide-to-lead-generation-what-is-it-how-to-improve-it-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-exit-intent-popups-what-are-they-how-do-they-work-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/exit-intent-popups-what-are-they-how-do-they-work.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-exit-intent-popups-what-are-they-how-do-they-work-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-grow-social-media-following-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/grow-social-media-following.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-grow-social-media-following-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-how-retail-websites-can-effectively-grow-their-email-lists-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/how-retail-websites-can-effectively-grow-their-email-lists.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-how-retail-websites-can-effectively-grow-their-email-lists-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-tailwind-popup-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/tailwind-popup.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-tailwind-popup-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-what-are-b-2-c-and-b-2-b-marketing-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/what-are-b2c-and-b2b-marketing.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-what-are-b-2-c-and-b-2-b-marketing-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-what-is-conversion-rate-how-to-improve-it-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/what-is-conversion-rate-how-to-improve-it.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-what-is-conversion-rate-how-to-improve-it-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-what-is-customer-retention-rate-how-to-calculate-and-improve-it-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/blog-pages/what-is-customer-retention-rate-how-to-calculate-and-improve-it.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-pages-what-is-customer-retention-rate-how-to-calculate-and-improve-it-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-help-pages-after-x-seconds-trigger-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/help-pages/after-x-seconds-trigger.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-help-pages-after-x-seconds-trigger-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-help-pages-gamified-popup-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/help-pages/gamified-popup.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-help-pages-gamified-popup-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-help-pages-wordpress-popup-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/help-pages/wordpress-popup.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-help-pages-wordpress-popup-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-privacy-policy-md": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/markdown-pages/privacy-policy.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-privacy-policy-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-terms-of-service-md": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/popupfox-web/src/markdown-pages/terms-of-service.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-pages-terms-of-service-md" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-shopify-popup-tsx": () => import("./../../../src/pages/shopify-popup.tsx" /* webpackChunkName: "component---src-pages-shopify-popup-tsx" */)
}

