import "./src/styles/global.css";
import React from "react";
require("prismjs/themes/prism-solarizedlight.css");

export function wrapPageElement({ element, props }) {
  return (
    <div
      {...props}
      id="main-container"
      className="font-inter text-sm font-medium"
    >
      {element}
    </div>
  );
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== (prevLocation && prevLocation.pathname)) {
    window.scrollTo(0, 0);
  }
};
